.ProfilePic {
  text-align: center;
  justify-content: center;
}

.ProfilePic img {
  border-radius: 50%;
  overflow: hidden;
  max-height: 90vh;
  max-width: 50vw;
  height: auto;
}
